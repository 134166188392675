// core
import React from 'react'

// components
import { Helmet } from 'react-helmet'

// stores
import internalStore from '../../stories/internal'

const ProductsGridMeta = React.memo(props => {
    const { categoryPath } = props

    let resultTitlePath = []

    const renderTitleFullPath = (category = null, reset = false) => {
        if (reset) {
            resultTitlePath = []
        }

        resultTitlePath.push(category.name)

        category.next && renderTitleFullPath(category.next)

        return resultTitlePath.join(' / ')
    }

    const title = renderTitleFullPath(categoryPath, true) + ' ' + (
      internalStore &&
      internalStore.footerData &&
      internalStore.footerData.shop &&
      internalStore.footerData.shop.additional_page_title
        ? internalStore.footerData.shop.additional_page_title
        : internalStore &&
        internalStore.footerData &&
        internalStore.footerData.shop &&
        internalStore.footerData.shop.name
          ? internalStore.footerData.shop.name
          : ' | bekoshop.sk'
    )
  
    return (
        <Helmet>
            <title>
                {title}
            </title>

            {/* Google / Search Engine Tags */}
            <meta itemProp='name' content={title} />
            <meta
                itemProp='description'
                content={`${
                    internalStore &&
                    internalStore.allAboutListProducts &&
                    internalStore.allAboutListProducts.category_description !== null &&
                    internalStore.allAboutListProducts.category_description !== ''
                        ? internalStore.allAboutListProducts.category_description
                        : ''
                }`}
            />
            <meta
                itemProp='image'
                content={`${
                    internalStore &&
                    internalStore.footerData !== null &&
                    internalStore.footerData !== undefined &&
                    internalStore.footerData.shop &&
                    internalStore.footerData.shop.email_asset_email_logo &&
                    internalStore.footerData.shop.email_asset_email_logo !== null &&
                    internalStore.footerData.shop.email_asset_email_logo !== undefined
                        ? internalStore.footerData.shop.email_asset_email_logo
                        : ''
                }`}
            />
            <link
                rel='sitemap'
                type='application/xml'
                title='Sitemap'
                href={`https://b2b.ravaltrade.sk/storage/public/xml/sitemap-bekoshop.xml`}
            />

            {/* Facebook Meta Tags */}
            <meta
                property='og:title'
                content={title}
            />
            <meta property='og:type' content='website' />
            <meta
                property='og:url'
                content={
                    internalStore &&
                    internalStore.footerData &&
                    internalStore.footerData.shop &&
                    internalStore.footerData.shop.url &&
                    internalStore.footerData.shop.url !== '' &&
                    internalStore.footerData.shop.url !== null
                        ? internalStore.allAboutListProducts &&
                          internalStore.allAboutListProducts.category &&
                          internalStore.allAboutListProducts.category.alias &&
                          internalStore.allAboutListProducts.category.alias !== null &&
                          internalStore.allAboutListProducts.category.alias !== ''
                            ? internalStore.footerData.shop.url +
                              internalStore.allAboutListProducts.category.alias
                            : ''
                        : ''}
            />
            <meta
                property='og:image'
                content={`${
                    internalStore &&
                    internalStore.footerData !== null &&
                    internalStore.footerData !== undefined &&
                    internalStore.footerData.shop &&
                    internalStore.footerData.shop.email_asset_email_logo &&
                    internalStore.footerData.shop.email_asset_email_logo !== null &&
                    internalStore.footerData.shop.email_asset_email_logo !== undefined
                        ? internalStore.footerData.shop.email_asset_email_logo
                        : ''
                }`}
            />

            {/* Twitter Meta Tags */}
            <meta
                property='twitter:domain'
                content={
                    internalStore &&
                    internalStore.allAboutListProducts &&
                    internalStore.allAboutListProducts.category_page_title !== null &&
                    internalStore.allAboutListProducts.category_page_title !== ''
                        ? internalStore.allAboutListProducts.category_page_title
                        : ''
                }
            />
        </Helmet>
    )
})

export default ProductsGridMeta

//basic
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './ProductDetail.scss'

//api
import { api } from '../../utils/api'

//custom components
import InfoBox from '../../components/basic/InfoBox/InfoBox'
import Gallery from '../../components/basic/Gallery/Gallery'
import Tabs from '../../components/complex/Tabs/Tabs'

//material-ui

//other libraries
import { formatMoney } from '../../utils/fuctions'
import Icon from '@mdi/react'
import { mdiScaleBalance, mdiShopping } from '@mdi/js'
import uuidv4 from 'uuid'
import queryString from 'query-string'
// import {Helmet} from "react-helmet";
import MetaTags from 'react-meta-tags'

import giftImage from '../../assets/images/gift.png'

//ravaltrade logo
import logoRavalTrade from '../../assets/images/logo-ravaltrade.jpg'

import ReactGA from 'react-ga4'
import ProductsAlternativeRelated from '../../components/complex/ProductsAlternativeRelated/ProductsAlternativeRelated'

class ProductDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingBtn: false,
    }
  }

  componentDidMount() {
    const { internal, ...other } = this.props
    this.props.internal.setRouterData(other.routerData)
      this.props.internal.setProductDetail(this.props.data)
      this.setState({ loading: false }, () => {
        ReactGA.event('view_item', {
          currency: 'EUR',
          value: this.props.data.use_discount_price ? this.props.data.discounted_price_with_vat : this.props.data.retail_price_with_vat,
          items: [
            {
              item_id: this.props.data.code,
              item_name: this.props.data.name,
              affiliation: 'Bekoshop.sk',
              coupon: this.props.data.coupons && this.props.data.coupons.length > 0 ? this.props.data.coupons[0].code : null,
              item_category: this.props.data.category_path_alt[0],
              item_category2: this.props.data.category_path_alt[1] ? this.props.data.category_path_alt[1] : null,
              item_category3: this.props.data.category_path_alt[2] ? this.props.data.category_path_alt[2] : null,
              price: this.props.data.use_discount_price ? this.props.data.discounted_price_with_vat : this.props.data.retail_price_with_vat,
            }
          ]
        })
        this.loadReviews()
      })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.routerData.location.pathname !== nextProps.routerData.location.pathname) {
      this.props.internal.setGlobalLoader(true)
      this.props.internal.routerData.history.push(`${nextProps.routerData.location.pathname}`)
    }
  }

  loadReviews = async () => {
    const URLqueryString = this.props.internal.routerData.history.location.search
    let queries = queryString.parse(URLqueryString)
    let alias = this.props.routerData.match.params.alias

    let load_reviews = await api({
      url: `reviews/${alias}`,
      method: 'GET',
    })

    if (load_reviews.success === true) {
      this.props.internal.setReviewData(load_reviews.data)
    }
  }

  rerender() {
    this.setState({ loading: true })

    Promise.all([
      this.loadReviews(),
    ]).then(() => {
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    })
  }

  handleGoToCategory = (url) => {
    this.props.internal.setGlobalLoader(true)
    let paramsArray = url.split('/')

    let matchPrams = this.props.internal.routerData.match.params
    let indexParamsArray = 0
    for (let i in matchPrams) {
      matchPrams[i] = undefined
      if (indexParamsArray < paramsArray.length) {
        matchPrams[i] = paramsArray[indexParamsArray]
        indexParamsArray++
      }
    }
    this.props.internal.routerData.match.params = matchPrams
    this.props.internal.routerData.history.push(`/${url}`)
    window.location.reload()
  }

  handleGoToHompage = () => {
    this.props.internal.setGlobalLoader(true)
    let matchPrams = this.props.internal.routerData.match.params
    for (let i in matchPrams) {
      matchPrams[i] = undefined
    }
    this.props.internal.routerData.match.params = matchPrams
    this.props.internal.routerData.history.push(`/`)
    window.location.reload()
  }

  renderBreadcrumbFullPath = () => {
    this.resultForRender = []
    const { category_path } = toJS(this.props.internal.productDetail)

    if (category_path && category_path !== null) {
      this.renderOneBreadcrumb(category_path)
    }

    return this.resultForRender
  }

  resultForRender = []

  renderOneBreadcrumb = (category_path) => {
    if (category_path.next === null) {
      this.resultForRender.push(
        <li key={uuidv4()}><a onClick={() => this.handleGoToCategory(category_path.alias)}>{category_path.name}</a></li>,
      )
    } else {
      this.resultForRender.push(
        <li key={uuidv4()}><a onClick={() => this.handleGoToCategory(category_path.alias)}>{category_path.name}</a></li>,
      )
      this.renderOneBreadcrumb(category_path.next)
    }
  }

  handleAddProductToShoppingCart = async () => {
    const { productDetail } = toJS(this.props.internal)

    let params = {}

    if (productDetail.fixedSet) {
      params.static_set = productDetail.id
      params.quantity = 1
    } else {
      params.product = productDetail.id
      params.quantity = 1
    }

    let shoppingCartData = toJS(this.props.internal.shoppingCartData)

    if (shoppingCartData.items !== null && shoppingCartData.items.length > 0) {
      let items = shoppingCartData.items
      if (productDetail.fixedSet) {
        let index = items.findIndex(x => x.id === productDetail.id)
        if (index > -1) {
          this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.STATIC_SET_IS_IN_SHOPPING_CART, true, 'openBasket')
          return
        }
      } else {
        let index = items.findIndex(x => x.product_id === productDetail.id)
        if (index > -1) {
          this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCT_IS_IN_SHOPPING_CART, true, 'openBasket')
          return
        }
      }
    }

    this.setState({ loadingBtn: true })


    let update_shop_cart = await api({
      url: 'shopping-cart',
      method: 'PUT',
      scns_token: localStorage.getItem('scns_token'),
      //TODO: vymazat
      // postman: true,
      params: params,
    })

    if (update_shop_cart.success === true) {
      this.props.internal.setShoppingCartData(update_shop_cart.data.data)
      this.setState({ loadingBtn: false })
      // window.init()
      this.props.internal.setSnackbarData(update_shop_cart.data.message, true, 'openBasket')

      this.props.internal.loginWrapperRef.rerender()
    }
  }

  handleAddProductToCompare = () => {
    let idProduct = this.props.data
    this.props.internal.setCompareProducts(idProduct)
    let compare = localStorage.getItem('compare')
    if (compare === null) {
      localStorage.setItem('compare', idProduct.alias)
      this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCTS_IS_ADDES_TO_COMPARE_LIST, true)
    } else {
      if (compare.includes(idProduct.alias)) {
        this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCTS_IS_READY_FOR_COMPARE, true)
      } else {
        let compareArray = compare.split(',')
        if (compareArray.length > 5) {
          this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.MAX_PRODUCT_FOR_COMPARE, true)
        } else {
          compare += ',' + idProduct.alias
          localStorage.setItem('compare', compare)
          this.props.internal.setSnackbarData(this.props.translate.i18n.GLOBAL.ALERTS.PRODUCTS_IS_ADDES_TO_COMPARE_LIST, true)
        }

      }
    }
    this.props.internal.loginWrapperRef.rerender()
  }

  setWatchDogData = () => {
    this.props.internal.setWatchDogData({
      isFixedSet: false,
      isProduct: true,
      item: this.props.data
    });
  }

  render() {
    const { productDetail } = toJS(this.props.internal)
    const { reviewData } = toJS(this.props.internal)
    let internal = toJS(this.props.internal)

    let user = JSON.parse(localStorage.getItem('user'))

    let routerData = {
      history: this.props.history,
      location: this.props.location,
      match: this.props.match,
    }

    const { page_title, seo_description, asset_image, name } = productDetail || {}
    const { shop } = internal.footerData || { shop: { name: '' } }

    const pageTitle = `${page_title || name || ''} ${shop ? (shop.additional_page_title || shop.name || '') : ''}`

    return (
      this.state.loading ?
        null
        :
        <div>
          <MetaTags>
            <title>{pageTitle || ''}</title>
            <meta
              name="description"
              content={`${seo_description || ''}`}
            />
            {/* OG */}
            <meta property="og:title" content={pageTitle || ''}/>
            <meta property="og:type" content="products"/>
            <meta property="og:url" content={`${shop ? shop.url + productDetail.alias : ''}`}/>
            <meta property="og:image" content={`${asset_image || window.location.origin + logoRavalTrade}`}
            />
            <meta property="og:description"
              content={`${seo_description || ''}`}
            />
            <meta property="og:locale" content="sk_SK"/>
            <meta property="og:site_name" content={`Bekoshop`}/>

            {/* TWITTER */}
            <meta property="twitter:card" content={`summary`}/>
            <meta property="twitter:description" content={`${seo_description || ''}`}/>
            <meta property="twitter:title" content={pageTitle || ''}/>
            <meta
              property="twitter:image"
              content={`${asset_image || window.location.origin + logoRavalTrade}`}
            />
            {/* TODO: ako nastavit twitter nazov? napr. @ambitas */}
            {/* <meta
                        property="twitter:site"
                        content={`${productDetail &&
                            productDetail.asset_image &&
                            productDetail.asset_image !== null &&
                            productDetail.asset_image !== ""
                                ?
                                productDetail.asset_image
                                :
                                logoRavalTrade
                        }`}
                    /> */}
            <meta property="twitter:domain" content={`${shop ? shop.url + productDetail.alias : ''}`}/>
          </MetaTags>
          <section className="main-header productDetail">
            <header>
              <div className="container">
                {/* <h1 className="h2 title">{productDetail.name}</h1> */}
                <ol className="breadcrumb breadcrumb-inverted">
                  <li><a onClick={() => this.handleGoToHompage()}><span className="icon icon-home"></span></a></li>
                  {this.renderBreadcrumbFullPath()}
                </ol>
              </div>
            </header>
          </section>
          <section className="product">
            <div className="main">
              <div className="container">
                <div className="row product-flex">


                  <div className="col-md-6 col-sm-12 product-flex-info">
                    <div className="clearfix">

                      {/* data-title={productDetail.category && productDetail.category.name ?  productDetail.category.name: ''} */}

                      {/*
                                        @rs
                                        added the 'add to cart' button, needed just so the user can see it no matter how long the description is
                                        */}
                      <div className="titleWrapper">
                        <h1 className="title text-left">{productDetail.name}</h1>
                        {this.state.loadingBtn ? <div className="lds-dual-ring addingToShoopingCart"/> : null}
                        <button style={{ display: `${this.state.loadingBtn ? 'none' : 'block'}` }}
                          className="btn btn-border btnOrangeBackground"
                          onClick={() => this.handleAddProductToShoppingCart()}>
                          <Icon path={mdiShopping} size={1}/>&nbsp;{this.props.translate.i18n.GLOBAL.BUTTONS.BUY}
                        </button>
                      </div>

                      <div className="clearfix">

                        <div className="price">
                          <span>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.PRICE_WITH_VAT}:&nbsp;</span>
                          {productDetail.use_discount_price && productDetail.retail_price_with_vat !== productDetail.discounted_price_with_vat && parseFloat(productDetail.discounted_price_with_vat) > 0 ?
                            <>
                                                        <span className="h3">
                                                            <small>
                                                                <s>
                                                                    {formatMoney(productDetail.retail_price_with_vat)}
                                                                </s>
                                                            </small> {formatMoney(productDetail.discounted_price_with_vat)}
                                                        </span>
                            </>
                            :
                            <span className="h3">
                                                        {formatMoney(productDetail.retail_price_with_vat)}
                                                    </span>}
                        </div>
                        <div className="price withoutVat">
                          <span>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.PRICE_WITHOUT_VAT}:&nbsp;</span>
                          {productDetail.use_discount_price && productDetail.retail_price_with_vat !== productDetail.discounted_price_with_vat && parseFloat(productDetail.discounted_price_with_vat) > 0 ?
                            <>
                                                        <span className="h3">
                                                            <small>
                                                                <s>
                                                                    {formatMoney(productDetail.retail_price)}
                                                                </s>
                                                            </small> {formatMoney(productDetail.discounted_price)}
                                                        </span>
                            </>
                            :
                            <span className="h3">
                                                        {formatMoney(productDetail.retail_price)}
                                                    </span>}
                        </div>
                        {productDetail.coupons && productDetail.coupons.length > 0 ? (
                          productDetail.coupons.map(coupon => (
                            <Fragment key={coupon.id}>
                              <hr/>
                              <div className="coupon">
                                <span>{this.props.translate.i18n.COMPONENTS.QUICK_VIEW.PRICE_WITH_CODE}:&nbsp; {coupon.code}</span>
                                <span className="h3">{formatMoney(coupon.price_with_coupon)}</span>
                              </div>
                            </Fragment>
                          ))
                        ) : null}
                        <hr/>
                        <div className="availability">
                          <span>{this.props.translate.i18n.COMPONENTS.QUICK_VIEW.WAREHOUSE_INFO}:&nbsp;</span>
                          <span>
                            {this.props.translate.i18n.PAGES.PRODUCT_DETAIL[productDetail.warehouse_status_computed.toUpperCase()]}
                          </span>
                        </div>
                        <hr/>
                        {productDetail.first_energy_class ? (
                          <>
                            <div className="energyClass">
                              <span>{this.props.translate.i18n.COMPONENTS.QUICK_VIEW.ENERGY_CLASS}:&nbsp;</span>
                              <span><img src={productDetail.first_energy_class.asset_image} alt={productDetail.first_energy_class.name} /></span>
                            </div>
                            <hr/>
                          </>
                        ) : null}
                        {productDetail.second_energy_class ? (
                          <>
                            <div className="energyClass">
                              <span>{this.props.translate.i18n.COMPONENTS.QUICK_VIEW.ENERGY_CLASS}:&nbsp;</span>
                              <span><img src={productDetail.second_energy_class.asset_image} alt={productDetail.second_energy_class.name} /></span>
                            </div>
                            <hr/>
                          </>
                        ) : null}
                        {false && productDetail.tags && productDetail.tags.length > 0 ?
                          <div className={'tags'}>
                            {productDetail.tags && productDetail.tags.map((tag, index) => {
                              return (
                                <span key={index} className={'oneTag'} style={{ backgroundColor: tag.color }}>{tag.name}</span>
                              )
                            })}
                            <hr/>
                          </div>
                          :
                          null
                        }

                        {productDetail.gifts && productDetail.gifts.length > 0 ?
                          <div className={'giftsContainer'}>
                            <strong>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.GIFTS}</strong>
                            <div className={'gifts'}>
                              {productDetail.gifts.map((gift, index) => {
                                return (
                                  <div key={index} className={'oneGift'}>
                                    <div className={'giftImage'}>
                                      <img src={gift.photo !== null ? gift.photo : giftImage} alt={gift.name}/>
                                    </div>
                                    <span className={'giftName'}>{gift.name} {gift.description !== null && gift.description !== '' ? ` - ${gift.description}` : ''}</span>
                                  </div>
                                )
                              })}
                            </div>
                            <hr/>
                          </div>
                          :
                          null
                        }

                        {productDetail.parameters.map((parameter, index) => {
                          let renderValue = ''
                          if (parameter.parameter_type_id === 3) {
                            renderValue = parameter.value
                          } else if (parameter.parameter_type_id === 1) {
                            if (parameter.value && parameter.value.name) {
                              renderValue = parameter.value.name
                            } else {
                              renderValue = 'N/A'
                            }

                          } else {
                            let multiValues = parameter.value
                            for (let i in multiValues) {
                              renderValue += multiValues[i].name
                              if (Number(i) < multiValues.length - 1) {
                                renderValue += ', '
                              }
                            }
                          }
                          return (

                            <InfoBox
                              key={index}
                              title={parameter.external_name}
                              value={renderValue}
                              className={'col-xs-6'}
                            />

                          )
                        })}
                        {productDetail.parameters.length > 0 ?
                          <hr/>
                          :
                          null
                        }

                        <div className={'col-xs-12'} style={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          position: 'relative',
                          marginTop: '20px',
                        }}>
                          {/* btnCompare btn-clean-dark */}
                          <button type="submit" className="btn btn-border btnWhiteBackground" onClick={() => this.handleAddProductToCompare()}>
                            <Icon path={mdiScaleBalance} size={1}/>{this.props.translate.i18n.GLOBAL.BUTTONS.COMPARE}
                          </button>
                          {this.state.loadingBtn ? <div className="lds-dual-ring addingToShoopingCart"/> : null}
                          <button style={{ display: `${this.state.loadingBtn ? 'none' : 'block'}` }} className="btn btn-border btnOrangeBackground" onClick={() => this.handleAddProductToShoppingCart()}>
                            <Icon path={mdiShopping} size={1}/>&nbsp;{this.props.translate.i18n.GLOBAL.BUTTONS.BUY}
                          </button>
                          {user !== null &&
                          <button
                            // style={{display: `${this.state.loadingBtn ? 'none' :  'block'}`}}
                            className="btn btn-border btnWhiteBackground"
                            data-toggle="modal"
                            data-target="#watchDog"
                            onClick={() => this.setWatchDogData()}>
                            {this.props.translate.i18n.GLOBAL.BUTTONS.WATCH_DOG}
                          </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12 product-flex-gallery ">


                    {/* <button type="submit" className="btn btn-buy" data-text={this.props.translate.i18n.GLOBAL.BUTTONS.BUY}></button> */}


                    <Gallery
                      data={productDetail}
                      product
                    />
                  </div>

                </div>
              </div>
            </div>

            <div>
              {productDetail.relatives.length > 0 && (
                <div className={'-mt-15'}>
                  <div className="container">
                    <h3>{this.props.translate.i18n.PAGES.PRODUCT_DETAIL.RELATED_PRODUCTS}</h3>
                  </div>
                  <div className={'bgGrayLight py-10 mb-15'}>
                    <div className="container">
                      <ProductsAlternativeRelated data={{products: productDetail.relatives}} />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="info bgGrayLight">
              <div className="container">
                <div className="row">
                  <div className={'tabs'}>
                    <Tabs
                      tabs={
                        // tabs
                        [
                          {
                            id: 'description',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.DESCRIPTION_AND_DOCS,
                            active: true,
                          },
                          productDetail.alternatives.length > 0 ? {
                            id: 'alternative',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.ALTERNATIVE_PRODUCTS,
                          } : null,
                          // productDetail.relatives.length > 0 ? {
                          //   id: 'related',
                          //   title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.RELATED_PRODUCTS,
                          // } : null,
                          productDetail.accessories.length > 0 ? {
                            id: 'accessories',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.ACCESSORIES,
                          } : null,
                          reviewData ? { id: 'rating', title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.REVIEW } : null,
                        ]
                      }
                      content={
                        // contet
                        [
                          {
                            id: 'description',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.DESCRIPTION_AND_DOCS,
                            active: true,
                            description: productDetail.description,
                            document: productDetail.documents,
                          },
                          productDetail.alternatives.length > 0 ? {
                            id: 'alternative',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.ALTERNATIVE_PRODUCTS,
                            products: productDetail.alternatives,
                          } : null,
                          // productDetail.relatives.length > 0 ? {
                          //   id: 'related',
                          //   title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.RELATED_PRODUCTS,
                          //   products: productDetail.relatives,
                          // } : null,
                          productDetail.accessories.length > 0 ? {
                            id: 'accessories',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.ACCESSORIES,
                            products: productDetail.accessories,
                          } : null,
                          reviewData ? {
                            id: 'rating',
                            title: this.props.translate.i18n.PAGES.PRODUCT_DETAIL.REVIEW,
                            reviewData,
                            my_review: productDetail.my_review,
                          } : null,
                        ]
                      }
                      routerData={routerData}
                      updateUrl={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
    )
  }
}

export default inject('internal', 'translate')(observer(ProductDetail))

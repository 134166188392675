module.exports = {
    // TODO: change to production API
    // base_url: 'https://b2b-rt-test.ambitas.sk/api/elica/v1/',
    // base_url: 'https://b2b-staging.ambitas.org/api/bekoshop/v1/', // test API
    // base_url: 'http://b2b.test/api/bekoshop/v1/', // dev API
    base_url: 'https://b2b.ravaltrade.sk/api/bekoshop/v1/', // production API
    // base_url: 'https://b2b.ravaltrade.sk/api/elica/v1/',
    // bse_url: 'https://58c84794-7a03-4852-8957-dd0a73787ac4.mock.pstmn.io/api/bekoshop/v1/'
    // base_url_postman: 'https://a2578949-d523-4f97-a7ae-f96cb1c2efd3.mock.pstmn.io/', //URL pre b2b mock
    // base_url_postman: 'https://58c84794-7a03-4852-8957-dd0a73787ac4.mock.pstmn.io',
    no_image: '../../assets/images/no_image.png'
}

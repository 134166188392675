//basic
import React from 'react'
import { observer } from 'mobx-react'

// api
import { api } from '../../utils/api'

// assets
import our_story_image from '../../assets/images/raval-trade.jpg'

// components
import Banner from '../../components/complex/Banner/Banner'
import TopProducts from '../../components/complex/TopProducts/TopProducts'

// i18n
import t from '../../stories/i18n'

// libraries
import Helmet from 'react-helmet'
import ReactGA from 'react-ga4'
import { isEqual } from 'lodash'

// stores
import internalStore from '../../stories/internal'

// styles
import '../HomePage/HomePage.scss'

class HomePage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            quickView: false,
            bannersIsLoading: true,
            topProductsIsLoading: true,
            verifyAccountMsg: null,
            fixedSetsIsLoading: true,
            ourStoryIsLoading: true,
            emailForResetPassword: null,
            modalForResetPassword: false,
            password: '',
            passwordConfirm: '',
            passwordIsNotEquals: false,
            passwordIsShort: false,
            loader: false
        }

        // ReactGA.initialize('UA-130881408-1')
    }

    componentDidMount() {
        document.body.removeAttribute('data-page')
        ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname })
        internalStore.setGlobalLoader(true)
        const { internal, ...other } = this.props
        internalStore.setRouterData(other)

        let token = this.props.match.params.token
        if (token) {
            this.verifyRegistration()
        }

        let resetPasswordToken = this.props.match.params.resetPasswordToken
        if (resetPasswordToken) {
            this.verifyResetPasswordToken(resetPasswordToken)
        }

        Promise.all([this.loadBaners()]).then(() => {
            this.setState({ loading: false })
            internalStore.setGlobalLoader(false)
        })
        this.loadTopProducts()
        this.loadFixedSets()
        this.loadSettings()
        internalStore.setGlobalLoader(false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const routerData = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
        }

        if (isEqual(routerData, this.routerData)) {
            this.routerData = routerData
        }
    }

    timeout = null

    verifyRegistration = async () => {
        let token = this.props.match.params.token
        let load_data = await api({
            method: 'GET',
            //TODO: vymazat ked bude na testovacom
            // postman: true,
            url: `account/register/verify/${token}`
        })

        if (load_data.success === true) {
            this.setState({ verifyAccountMsg: load_data.data.message })
            internalStore.setSnackbarData(load_data.data.message, true)
            // this.timeout = setTimeout(()=>{
            //     document.getElementById("snackbar").style.display = "none";
            // }, TIMEOUT)
        }
    }

    verifyResetPasswordToken = async token => {
        let verify_data = await api({
            url: `account/password/verify/${token}`,
            method: 'GET'
        })

        // console.log("verifyResetPasswordToken", verify_data)

        if (verify_data.success === true) {
            this.setState({
                emailForResetPassword: verify_data.data.data.email,
                modalForResetPassword: true
            })
            document.body.className += ' ' + 'modal-open'
            let div = document.createElement('div')
            div.className += ' modal-backdrop fade in'
            div.setAttribute('id', 'backgroundDialog')
            document.body.appendChild(div)
        } else {
            internalStore.setSnackbarErrorData(verify_data.data.message)
        }
    }

    handleResetPassword = async () => {
        let password = this.state.password
        let passwordConfrim = this.state.passwordConfirm

        if (password !== passwordConfrim) {
            // this.props.internal.setSnackbarData("hesla sa nezhoduje", true)
            this.setState({ passwordIsNotEquals: true })
            return
        }

        if (password.length < 8) {
            this.setState({ passwordIsShort: true })
            return
        }

        this.setState({ loader: true })

        let resetPasswordToken = this.props.match.params.resetPasswordToken

        // console.log("this.props", this.props)

        let reset_password = await api({
            method: 'POST',
            url: 'account/password/reset',
            params: {
                token: resetPasswordToken,
                email: this.state.emailForResetPassword,
                password: this.state.password,
                password_confirmation: this.state.passwordConfirm
            }
        })

        if (reset_password.success === true) {
            internalStore.setSnackbarData(reset_password.data.message, true)
            this.props.history.push('/')
            this.setState({ modalForResetPassword: false })
            document.getElementById('backgroundDialog').remove()
            this.setState({ loader: false })
        }
    }

    handeSetPassword = (field, val) => {
        // console.log("field", field, "\nval", val.target.value)
        this.setState({
            [field]: val.target.value,
            passwordIsNotEquals: false,
            passwordIsShort: false
        })
    }

    loadSettings = async () => {
        // let load_data = await api({
        //     method: 'GET',
        //     url: 'settings'
        // })
        //
        // // console.log("settings", load_data)
        // if (load_data.success === true) {
        //     this.props.internal.setFooterData(load_data.data.data)
        //     this.setState({ ourStoryIsLoading: false })
        // }
        // await this.props.internal.loadFooterData();
        this.setState({ ourStoryIsLoading: false })
    }

    loadBaners = async () => {
        let load_data = await api({
            method: 'GET',
            url: 'banners'
        })

        if (load_data.success === true) {
            internalStore.setBannersData(load_data.data.data)
            this.setState({ bannersIsLoading: false })
        }

        return true
    }

    loadTopProducts = async () => {
        let load_data = await api({
            method: 'GET',
            url: 'top-products'
        })

        if (load_data.success === true) {
            internalStore.setTopProductsData(load_data.data.data)
            this.setState({ topProductsIsLoading: false })
            window.init()
        }
        return true
    }

    loadFixedSets = async () => {
        let load_data = await api({
            method: 'GET',
            url: 'fixed-sets'
        })

        if (load_data.success === true) {
            internalStore.setFixedSets(load_data.data)
            this.setState({ fixedSetsIsLoading: false })
            window.init()
        }
    }

    routerData = {
        history: this.props.history,
        location: this.props.location,
        match: this.props.match
    }

    render() {
        let page_title = 'Bekoshop'
        let seo_description = ''

        if (
            internalStore &&
            internalStore.topMenu &&
            internalStore.topMenu.length > 0 &&
            internalStore.topMenu !== null &&
            internalStore.topMenu !== undefined
        ) {
            let tmpHomeObj = internalStore.topMenu.filter(item => item.frontend_menu_type_id === 1)
            if (tmpHomeObj.length > 0) {
                if (
                    tmpHomeObj[0].page_title &&
                    tmpHomeObj[0].page_title !== null &&
                    tmpHomeObj[0].page_title !== ''
                ) {
                    page_title = tmpHomeObj[0].page_title
                } else {
                    page_title = tmpHomeObj[0].name
                }
                seo_description = tmpHomeObj[0].page_description
            } else {
                page_title = ''
                seo_description = ''
            }
        }

        return this.state.loading ? null : (
            <div
                style={{
                    display: `${internalStore.globalLoader === true ? 'none' : 'block'}`
                }}
            >
                {this.state.ourStoryIsLoading ? null : (
                    <Helmet>
                        <title>
                            {page_title}{' '}
                            {internalStore.footerData !== null &&
                            internalStore.footerData !== undefined &&
                            internalStore.footerData.shop &&
                            internalStore.footerData.shop.additional_page_title
                                ? internalStore.footerData.shop.additional_page_title
                                : internalStore.footerData &&
                                  internalStore.footerData.shop &&
                                  internalStore.footerData.shop.name
                                ? internalStore.footerData.shop.name
                                : ''}
                        </title>
                        <meta name='description' content={seo_description} />
                        <meta itemProp='name' content={`bekoshop.sk`} />
                        <meta itemProp='description' content={seo_description} />
                        <meta
                            itemProp='image'
                            content={`${
                                internalStore.footerData !== null &&
                                internalStore.footerData !== undefined &&
                                internalStore.footerData.shop &&
                                internalStore.footerData.shop.email_asset_email_logo &&
                                internalStore.footerData.shop.email_asset_email_logo !== null &&
                                internalStore.footerData.shop.email_asset_email_logo !== undefined
                                    ? internalStore.footerData.shop.email_asset_email_logo
                                    : ''
                            }`}
                        />
                        <link
                            rel='sitemap'
                            type='application/xml'
                            title='Sitemap'
                            href={`https://b2b.ravaltrade.sk/storage/public/xml/sitemap-bekoshop.xml`}
                        />
                        <meta property='og:title' content={page_title} />
                        <meta property='og:type' content='website' />
                        <meta
                            property='og:url'
                            content={`${
                                internalStore &&
                                internalStore.footerData &&
                                internalStore.footerData.shop &&
                                internalStore.footerData.shop.url &&
                                internalStore.footerData.shop.url !== '' &&
                                internalStore.footerData.shop.url !== null
                                    ? internalStore.footerData.shop.url
                                    : ''
                            }
                    `}
                        />
                        <meta
                            property='og:image'
                            content={`${
                                internalStore &&
                                internalStore.footerData &&
                                internalStore.footerData.shop &&
                                internalStore.footerData.shop.email_asset_email_logo &&
                                internalStore.footerData.shop.email_asset_email_logo !== '' &&
                                internalStore.footerData.shop.email_asset_email_logo !== null
                                    ? internalStore.footerData.shop.email_asset_email_logo
                                    : ''
                            }`}
                        />

                        <meta property='twitter:domain' content={page_title} />
                    </Helmet>
                )}
                <Banner
                // data={bannerData}
                />
                {this.state.topProductsIsLoading === false ? (
                    <TopProducts
                        // data={topProductsData}
                        title={t.i18n.COMPONENTS.TOP_PRODUCTS.TITLE}
                        routerData={this.routerData}
                        topProducts
                    />
                ) : null}
                {internalStore.footerData && internalStore.footerData.shop ? (
                    <section
                        className={'bannerParalax '}
                        style={{
                            backgroundImage: `url('${
                                internalStore.footerData.shop.hasOwnProperty(
                                    'asset_our_story_image'
                                ) && internalStore.footerData.shop.asset_our_story_image !== null
                                    ? internalStore.footerData.shop.asset_our_story_image
                                    : our_story_image
                            }')`
                            // height: 350
                        }}
                    >
                        <div className={'overlay'}>
                            <div className={'container'}>
                                <div className={'row'}>
                                    <div className={'col-md-offset-2 col-md-8 text-center'}>
                                        <h2>
                                            {internalStore.footerData.shop.hasOwnProperty(
                                                'our_story_title'
                                            ) &&
                                            internalStore.footerData.shop.our_story_title !== null
                                                ? internalStore.footerData.shop.our_story_title
                                                : ''}
                                        </h2>
                                        <p>
                                            {internalStore.footerData.shop.hasOwnProperty(
                                                'our_story_description'
                                            ) &&
                                            internalStore.footerData.shop.our_story_description !==
                                                null
                                                ? internalStore.footerData.shop
                                                      .our_story_description
                                                : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null}
                {this.state.fixedSetsIsLoading === false &&
                internalStore.fixedSetsData.data.length > 0 ? (
                    <TopProducts
                        title={t.i18n.COMPONENTS.FIXED_SETS.TITLE}
                        routerData={this.routerData}
                        fixedSets
                        id={'vyhodne-sety'}
                        style={{paddingTop:'160px', marginTop:'-160px'}}
                    />
                ) : null}
                {this.state.verifyAccountMsg !== null ? (
                    <span className={'snackbarMessage'} id={'snackbar'}>
                        {this.state.verifyAccountMsg}
                    </span>
                ) : null}
                {this.state.modalForResetPassword ? (
                    <div
                        className='modal fade in'
                        id='resetPassword'
                        role='dialog'
                        style={{
                            display: `${this.state.modalForResetPassword ? 'block' : 'none'}`
                        }}
                    >
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <button type='button' className='close' data-dismiss='modal'>
                                        &times;
                                    </button>
                                    <h4 className='modal-title'>
                                        {t.i18n.PAGES.MY_ACCOUNT.RESET_PASSWORD}
                                    </h4>
                                </div>
                                {this.state.loader ? (
                                    <div className={'modal-body'}>
                                        <div className='lds-dual-ring resetingPassword' />
                                    </div>
                                ) : (
                                    <div className='modal-body'>
                                        {this.state.passwordIsNotEquals ? (
                                            <p className={'passIsNotEqual'}>
                                                {
                                                    t.i18n.COMPONENTS
                                                        .REGISTER_FORM.PASS_IS_NOT_EQUAL
                                                }
                                            </p>
                                        ) : null}
                                        {this.state.passwordIsShort ? (
                                            <p className={'passIsNotEqual'}>
                                                {
                                                    t.i18n.COMPONENTS
                                                        .REGISTER_FORM.MIN_PASS_LENGTH
                                                }
                                            </p>
                                        ) : null}
                                        <div className='form-group'>
                                            <label htmlFor={'password'}>
                                                {
                                                    t.i18n.COMPONENTS
                                                        .REGISTER_FORM.PASSWORD
                                                }
                                            </label>
                                            <input
                                                type='password'
                                                className={`form-control `}
                                                id='password'
                                                placeholder={
                                                    t.i18n.COMPONENTS
                                                        .REGISTER_FORM.PASSWORD
                                                }
                                                value={this.state.password}
                                                onChange={val =>
                                                    this.handeSetPassword('password', val)
                                                }
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label htmlFor={'passwordConfirm'}>
                                                {
                                                    t.i18n.COMPONENTS
                                                        .REGISTER_FORM.PASSWORD_CONFIRM
                                                }
                                            </label>
                                            <input
                                                type='password'
                                                className={`form-control `}
                                                id='passwordConfirm'
                                                placeholder={
                                                    t.i18n.COMPONENTS
                                                        .REGISTER_FORM.PASSWORD_CONFIRM
                                                }
                                                value={this.state.passwordConfirm}
                                                onChange={val =>
                                                    this.handeSetPassword('passwordConfirm', val)
                                                }
                                            />
                                        </div>

                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            onClick={this.handleResetPassword}
                                        >
                                            {t.i18n.PAGES.MY_ACCOUNT.RESET_BTN}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}
export default observer(HomePage)

//basic
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'

//style
import './Contact.scss'

//api
import { api } from '../../utils/api'

//custom components

//material-ui

//other libraries
import Helmet from 'react-helmet';


class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingBtn: false,
            email: '',
            username: '',
            subject: '',
            text: '',
            errors: {}
        }
    }

    componentDidMount() {
        const { routerData } = this.props
        this.props.internal.setRouterData(routerData)
        this.loadSettings()
        this.load("https://maps.googleapis.com/maps/api/js?key=AIzaSyAIwutdcmFxNH22msQ_ngjFfViTaL_ysgg", () => {
            //AIzaSyAIwutdcmFxNH22msQ_ngjFfViTaL_ysgg
            this.initMap()
        })
    }



    loadSettings = async() => {
        await this.props.internal.loadFooterData();
    
        this.setState({ loading: false }, () => {this.props.internal.setGlobalLoader(false)})
    }

    handleSendForm = () => {
        let name = this.state.username.trim();
        let email = this.state.email.trim();
        let subject = this.state.subject.trim();
        let comment = this.state.text.trim();
        
        let errors = {};
        let hasErrors = false;
        
        if (name === '') {
          hasErrors = true;
          errors.username = [];
        }
        if (email === '') {
          hasErrors = true;
          errors.email = [];
        }
        if (subject === '') {
          hasErrors = true;
          errors.subject = [];
        }
        if (comment === '') {
          hasErrors = true;
          errors.text = [];
        }
        
        this.setState({errors});

        if(true === hasErrors) {
            this.props.internal.setSnackbarErrorData(this.props.translate.i18n.GLOBAL.ALERTS.REQUIRED_FIELDS_IS_EMPTY)
            return
        }
        else {
            this.sendForm()
        }
    }

    sendForm = async() => {
        this.setState({loadingBtn: true})
        let contact_form = await api({
            url: "contact/send",
            method :"POST",
            params: {
                username: this.state.username,
                email: this.state.email,
                subject: this.state.subject,
                text: this.state.text,
            }
        })

      // console.log(contact_form);
      
        if(contact_form.success === true || (contact_form.success === false && contact_form.status === 201)) {
            this.props.internal.setSnackbarData(contact_form.data.message, true)
    
            this.setState({
                username: '',
                email: '',
                subject: '',
                text: '',
            })
        }
        else {
            this.props.internal.setSnackbarErrorData(contact_form.data.message)
          this.setState({errors: contact_form.data.data.errors || {}});
    
            // this.setState({
            //     username: '',
            //     email: '',
            //     subject: '',
            //     text: '',
            // })
        }

        this.setState({loadingBtn: false})
    }

    load (src, callback) {
        var script = document.createElement('script');
        var loaded;
        script.setAttribute('src', src);
        if (callback) {
            script.onreadystatechange = script.onload = function() {
                if (!loaded) {
                    callback();
                }
                loaded = true;
            };
        }
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    initMap() {
        setTimeout(() => {
            // const footerData  = toJS(this.props.internal.footerData)
            const {shop}  = this.props.internal.footerData;
            let map = new window.google.maps.Map(document.getElementById('map'), {
                center: {lat: Number(shop.latitude), lng: Number(shop.longitude) },
                zoom: 16,

            });

            let marker = new window.google.maps.Marker({
                position: {lat: Number(shop.latitude), lng: Number(shop.longitude) },
                title: shop.name
            });

            marker.setMap(map)
        }, 3000)
      }

    render() {
        const footerData  = toJS(this.props.internal.footerData)
      
        return (
            this.state.loading ?
            null
            :
            <div className={'contactSection'}>
            <Helmet >
                    <title>
                        {
                            this.props.data.page_title &&
                            this.props.data.page_title !== undefined &&
                            this.props.data.page_title !== null
                                ?
                                this.props.data.page_title
                                :
                                this.props.data.name
                        }
                        {' '}
                        {
                            footerData &&
                            footerData !== null &&
                            footerData !== undefined &&
                            footerData.shop &&
                            footerData.shop.additional_page_title
                                ?
                                footerData.shop.additional_page_title
                                :
                                footerData &&
                                footerData !== null &&
                                footerData !== undefined &&
                                footerData.shop &&
                                footerData.shop.name
                                    ?
                                    footerData.shop.name
                                    :
                                    ""
                        }
                    </title>
                    <meta name="description"
                        content={`${
                            this.props.data.page_description &&
                            this.props.data.page_description !== undefined &&
                            this.props.data.page_description !== null
                                ?
                                this.props.data.page_description
                                :
                                ""
                        }
                        
                        `}
                    />
                    
                    <meta itemProp="name" content={`bekoshop.sk`}/>
                    <meta itemProp="description"
                        content={`${
                            this.props.data.page_description &&
                            this.props.data.page_description !== undefined &&
                            this.props.data.page_description !== null
                                ?
                                this.props.data.page_description
                                :
                                ""
                        }`}
                    />
                    <meta itemProp="image"
                        content={`${
                            footerData !== null &&
                            footerData !== undefined &&
                            footerData.shop &&
                            footerData.shop.email_asset_email_logo &&
                            footerData.shop.email_asset_email_logo !== null &&
                            footerData.shop.email_asset_email_logo !== undefined
                                ?
                                footerData.shop.email_asset_email_logo
                                :
                                ""
                            }`
                        }
                    />
                    <link rel="sitemap" type="application/xml" title="Sitemap" href={`https://b2b.ravaltrade.sk/storage/public/xml/sitemap-bekoshop.xml`} />

                    <meta
                        property="og:title"
                        content={
                            this.props.data.page_title &&
                            this.props.data.page_title !== undefined &&
                            this.props.data.page_title !== null
                                ?
                                this.props.data.page_title
                                :
                                ""
                        }
                    />
                    <meta property="og:type" content="website"/>
                    <meta property="og:url"
                        content={`${
                            this.props.data.shop &&
                            this.props.data.shop.url !== undefined &&
                            this.props.data.shop.url !== null
                                ?
                                this.props.data.shop.url
                                :
                                ""
                        }
                    `}/>
                    <meta
                        property="og:image"
                        content={`${
                            footerData &&
                            footerData !== null &&
                            footerData !== undefined &&
                            footerData.shop &&
                            footerData.shop.additional_page_title
                                ?
                                footerData.shop.email_asset_email_logo
                                :
                                ""
                        }`}/>

                    <meta
                        property="twitter:domain"
                        content={
                            this.props.data.page_title &&
                            this.props.data.page_title !== undefined &&
                            this.props.data.page_title !== null
                                ?
                                this.props.data.page_title
                                :
                                ""
                        }
                    />
                </Helmet>

                <section className='main-header productDetail'>
                    <header>
                        <div className='container'>
                            <h1 className={'title'}>{this.props.translate.i18n.PAGES.CONTACT.TITLE}</h1>
                        </div>
                    </header>
                </section>
                <section className='contact bgGrayLight'>
                    <div className={'map'} id={'map'}>
                        {/* <GoogleMapReact
                            bootstrapURLKeys={{ key: '' }} //AIzaSyAIwutdcmFxNH22msQ_ngjFfViTaL_ysgg
                            defaultCenter={{ lat: Number(footerData.shop.latitude), lng: Number(footerData.shop.longitude) }}
                            yesIWantToUseGoogleMapApiInternals
                            defaultZoom={11}
                        >
                            <Marker mapCenter={{lat: Number(footerData.shop.latitude), lng: Number(footerData.shop.longitude)} }></Marker>
                        </GoogleMapReact> */}

                        {/* <Map
                            google={{apiKey: "AIzaSyAIwutdcmFxNH22msQ_ngjFfViTaL_ysgg"}}
                            initialCenter={{ lat: Number(footerData.shop.latitude), lng: Number(footerData.shop.longitude) }}
                        >

                        </Map> */}
                    </div>

                    {/* <script async defer src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAIwutdcmFxNH22msQ_ngjFfViTaL_ysgg&callback=initMap`}type="text/javascript"></script> */}
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1'>
                                <div className='contact-block'>
                                    <div className='contact-info'>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <figure className='text-center'>
                                                    <span className='icon icon-map-marker' />
                                                    <figcaption>
                                                        <strong>{this.props.translate.i18n.PAGES.CONTACT.WHERE_ARE_WE}</strong>
                                                        <span>
                                                            {footerData.address.street_name} {footerData.address.street_no}<br />
                                                            {footerData.address.zip} {footerData.address.city}<br />
                                                            {footerData.address.country}
                                                        </span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className='col-sm-4'>
                                                <figure className='text-center'>
                                                    <span className='icon icon-envelope' />
                                                    <figcaption>
                                                        <strong>{this.props.translate.i18n.PAGES.CONTACT.CALL_US}</strong>
                                                        <span>
                                                            <a href={`tel:${footerData.shop.contact_phone}`}>{footerData.shop.contact_phone}</a>
                                                            <br />
                                                            <a href={`mailto:${footerData.shop.contact_email}`}>{footerData.shop.contact_email}</a>
                                                        </span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className='col-sm-4'>
                                                <figure className='text-center'>
                                                    <span className='icon icon-clock' />
                                                    <figcaption>
                                                        <strong>{this.props.translate.i18n.PAGES.CONTACT.WORKING_HOURS}</strong>
                                                        <span>
                                                            <strong>{this.props.translate.i18n.COMPONENTS.FOOTER.WEEK}: </strong>{footerData.shop.opening_hours_mon_fri}
                                                            <br />
                                                            <strong>{this.props.translate.i18n.COMPONENTS.FOOTER.WEEKEND}: </strong>{footerData.shop.opening_hours_sat_sun}
                                                        </span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='banner'>
                                        <div className='row'>
                                            <div className='col-md-offset-1 col-md-10 text-center'>
                                                <h2 className='title'>{this.props.translate.i18n.PAGES.CONTACT.SEND_EMAIL_TITLE}</h2>


                                                <div className='contact-form-wrapper'>
                                                    <a
                                                        href={'#showForm'}
                                                        className='btn btn-clean open-form'
                                                        data-text-open={this.props.translate.i18n.PAGES.CONTACT.CONTACT_US_VIA_FORM}
                                                        data-text-close={this.props.translate.i18n.PAGES.CONTACT.HIDDE_FORM_BUTTON}
                                                    >
                                                        {this.props.translate.i18n.PAGES.CONTACT.CONTACT_US_VIA_FORM}
                                                    </a>

                                                    <div className='contact-form clearfix'>
                                                        <div>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className={`form-group`}>
                                                                        <input
                                                                            id='Name'
                                                                            name='Name'
                                                                            type='text'
                                                                            value={this.state.username}
                                                                            onChange={event => this.setState({username: event.currentTarget.value})}
                                                                            className={`form-control ${this.state.errors.hasOwnProperty('username') ? 'invalid' : ''}`}
                                                                            placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.NAME} *`}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <div className={`form-group`}>
                                                                        <input
                                                                            id='Email'
                                                                            name='Email'
                                                                            type='email'
                                                                            value={this.state.email}
                                                                            onChange={event => this.setState({email: event.currentTarget.value})}
                                                                            className={`form-control ${this.state.errors.hasOwnProperty('email') ? 'invalid' : ''}`}
                                                                            placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.EMAIL} *`}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-12'>
                                                                    <div className={`form-group`}>
                                                                        <input
                                                                            id='Subject'
                                                                            name='Subject'
                                                                            type='text'
                                                                            value={this.state.subject}
                                                                            onChange={event => this.setState({subject: event.currentTarget.value})}
                                                                            className={`form-control ${this.state.errors.hasOwnProperty('subject') ? 'invalid' : ''}`}
                                                                            placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.SUBJECT} *`}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-12'>
                                                                    <div className={`form-group`}>
                                                                        <textarea
                                                                            id='Comment'
                                                                            name='Comment'
                                                                            className={`form-control ${this.state.errors.hasOwnProperty('text') ? 'invalid' : ''}`}
                                                                            value={this.state.text}
                                                                            onChange={event => this.setState({text: event.currentTarget.value})}
                                                                            placeholder={`${this.props.translate.i18n.COMPONENTS.FORM.MESSAGE} *`}
                                                                            rows={10}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-12 text-center' style={{position: "relative"}}>
                                                                    {this.state.loadingBtn
                                                                        ?
                                                                        <div className='lds-dual-ring loadingBtn' />
                                                                        :
                                                                        <button
                                                                            // type='submit'

                                                                            onClick={this.handleSendForm}
                                                                            className='btn btn-clean'
                                                                        >
                                                                            {this.props.translate.i18n.PAGES.CONTACT.SEND_EMAIL_BUTTON}
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='contact-info'>
                                        <div className='row'>
                                            <div className='col-xs-6 col-sm-4'>
                                                <figure>
                                                    <figcaption>
                                                        <strong>{this.props.translate.i18n.PAGES.CONTACT.ICO}</strong>
                                                        <span>{footerData.shop.id_no}</span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className='col-xs-6 col-sm-4'>
                                                <figure>
                                                    <figcaption>
                                                        <strong>{this.props.translate.i18n.PAGES.CONTACT.IC_DPH}</strong>
                                                        <span>{footerData.shop.id_vat}</span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className='col-xs-6 col-sm-4'>
                                                <figure>
                                                    <figcaption>
                                                        <strong>{this.props.translate.i18n.PAGES.CONTACT.IBAN}</strong>
                                                        <span>{footerData.shop.iban}</span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            {/* <div className='col-xs-12 col-sm-6'>
                                                <figure>
                                                    <figcaption>
                                                        <strong>KONTAKT pre OBCHOD v pracovné dni PONDELOK – PIATOK od 8.00 do 15.00</strong>
                                                        <span>
                                                            <a href={'tel:+421 33 77 301 91'}>+421 33 77 301 91</a>
                                                            <br />
                                                            < a href={'mailto:info@ravaltrade.sk'}>info@ravaltrade.sk</a>
                                                        </span>
                                                    </figcaption>
                                                </figure>
                                            </div> */}
                                            {/* <div className='col-xs-12 col-sm-6'> */}
                                            <div className='col-xs-12 '>
                                                <figure>
                                                    <figcaption>
                                                        <strong>{footerData.shop.feedback_description}</strong>
                                                        <span>
                                                            <a href={`tel:${footerData.shop.feedback_phone}`}>{footerData.shop.feedback_phone}</a>
                                                            <br />
                                                            <a href={`mailto:${footerData.shop.feedback_email}`}>{footerData.shop.feedback_email}</a>
                                                        </span>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default inject('internal', 'translate')(observer(Contact))

// core
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Provider, observer } from 'mobx-react'

import InternalStore from './stories/internal'
import Translate from './stories/i18n'

// styling
import '../src/styles/App.scss'

//custom components
import Header from './components/global/Header/Header'
import Footer from './components/global/Footer/Footer'
import ScrollTop from './components/global/ScrollTop/ScrollTop'
import RouteLogin from './routes/Login/Login'
import Loader from './components/global/Loader/Loader'
import Snackbar from './components/global/Snackbar/Snackbar'
import ResetPasswordModal from './components/global/ResetPasswordModal/ResetPasswordModal'
import RegisterModal from './components/global/RegisterModal/RegisterModal'
import WatchDogDialog from './components/global/WatchDogDialog/WatchDogDialog'
import InviteFriendDialog from './components/global/InviteFriendDialog/InviteFriendDialog'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import ReactGA from 'react-ga4'

class App extends Component {
    componentDidMount() {
      ReactGA.initialize('UA-130881408-1')
      // ReactGA.initialize('UA-162220715-1', {debug: true})
    }

    render() {
        return (
            <>
                {/*<CookieConsent*/}
                {/*    buttonText={'Rozumiem'}*/}
                {/*    buttonStyle={{*/}
                {/*        border: 0,*/}
                {/*        background: 'white',*/}
                {/*        boxShadow: 'none',*/}
                {/*        color: '#0391da',*/}
                {/*        cursor: 'pointer',*/}
                {/*        flex: '0 0 auto',*/}
                {/*        padding: '10px 10px',*/}
                {/*        margin: '20px 10px'*/}
                {/*    }}*/}
                {/*    style={{ fontSize: 14, background: '#0391da', color: '#ffffff' }}*/}
                {/*>*/}
                {/*    Náš web používa súbory cookies, ktoré vylepšujú naše služby. Používaním našej*/}
                {/*    webstránky súhlasíte s ich používaním.&nbsp;*/}
                {/*    <a*/}
                {/*        rel={'noreferrer'}*/}
                {/*        target={'_blank'}*/}
                {/*        href={`https://policies.google.com/technologies/cookies?hl=sk`}*/}
                {/*        style={{ color: '#ffffff', textDecoration: 'underline' }}*/}
                {/*    >*/}
                {/*        Viac informácií*/}
                {/*    </a>*/}
                {/*</CookieConsent>*/}
                <Helmet>
                    <link rel='icon' type='image/x-icon' href='/favicon.ico' />
                </Helmet>
                <Provider internal={InternalStore} translate={Translate}>
                    <div>
                        {/* <div className="page-loader"></div> */}
                        <Loader loading={InternalStore.globalLoader} />
                        <Header />
                        <RouteLogin />
                        <Footer />
                        <ScrollTop />
                        <Snackbar open={InternalStore.snackbarData.open} />
                        <ResetPasswordModal />
                        <RegisterModal />
                        <WatchDogDialog />
                        {/* <DetailOrderDialog/> */}
                        <InviteFriendDialog />
                    </div>
                </Provider>
            </>
        )
    }
}

export default observer(App)

ReactDOM.render(<App />, document.getElementById('root'))
